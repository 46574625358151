import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import useAxios from "../../hooks/useAxios";
import { AiFillExclamationCircle } from "react-icons/ai";
import clsx from "clsx";
import { FaPencilAlt } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";

const PayrrolWorkerItemEdit = ({
    positionId,
    code,
    costCenterId,
    itemValue,
    payrrolWorkerItemId,
    value,
    type,
    factor,
    note,
    defaultValue,
    canUpdateItems,
    setItemDescription
}) => {

    const [currentValue, setCurrentValue] = useState(0);

    const [currentNote, setCurrentNote] = useState('');

    const [showDescription, setShowDescription] = useState(false);

    const [hasError, setHasError] = useState(false);

    const [warningAlert, setWarningAlert] = useState(false);

    const [{ loading: loadingUpdate, error }, updateCurrentItem] = useAxios({ url: `/payrrol-worker-items/${payrrolWorkerItemId}`, method: 'PUT' }, { useCache: false, manual: true });

    useEffect(() => {
        if (error) {
            setHasError(true);
        }
    }, [error])

    useEffect(() => {
        if (note) setCurrentNote(note);
    }, [note])

    useEffect(() => {
        if (value) setCurrentValue(Number(value));
    }, [value])

    const handleChange = (e) => {
        setHasError(false);

        if (itemValue?.max && Number(itemValue?.max) < Number(e.target.value)) {
            setWarningAlert(true);
        } else {
            setWarningAlert(false);
        }

        try {

            console.log(e.target.value);

            setCurrentValue(e.target.value);

            updateCurrentItem({
                data: {
                    value: Number(e.target.value),
                    note: currentNote
                }
            });

        } catch (error) {
        }
    }

    const handleChangeNote = (e) => {
        setHasError(false);

        updateCurrentItem({
            data: {
                value: Number(currentValue),
                note: currentNote
            }
        });
    }

    return (
        <div>
            {
                payrrolWorkerItemId ?
                    <div className="text-center justify-content-center d-flex align-items-center" style={{ width: 200 }}>
                        {
                            factor &&
                            <p className="text-primary me-2 mb-0" style={{ fontSize: '14px' }}>
                                {factor}
                            </p>
                        }
                        {
                            canUpdateItems ?
                                <div>
                                    {type === 'variable' &&
                                        <div className="d-flex align-items-center">
                                            <CurrencyFormat
                                                className={clsx(["form-control"], {
                                                    'border border-danger': hasError,
                                                    'border border-warning': warningAlert
                                                })}
                                                style={{ minWidth: 100 }}
                                                value={currentValue}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                isNumericString
                                                onValueChange={(e) => handleChange({ target: { value: e.value } })}
                                                placeholder="Por favor ingrese el valor"
                                            />

                                            {
                                                loadingUpdate &&
                                                <div className="spinner" style={{ height: 15, width: 20, marginLeft: 10 }}>
                                                    <div className="double-bounce1 bg-primary"></div>
                                                    <div className="double-bounce2 bg-primary"></div>
                                                </div>
                                            }

                                            {
                                                !loadingUpdate && hasError ?
                                                    <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                                                    :
                                                    null
                                            }

                                            {
                                                !loadingUpdate && warningAlert ?
                                                    <AiFillExclamationCircle title={`¡Cuidado! está pasando por encima del limite general: ${Number(itemValue?.max).toLocaleString()}`} className="text-warning" style={{ marginLeft: 10, fontSize: 15 }} />
                                                    :
                                                    null
                                            }
                                        </div>
                                    }
                                    {
                                        type === 'fijo' &&
                                        <div className="input-group">
                                            <CurrencyFormat
                                                className={clsx(["form-control"], {
                                                    'border border-success text-success': currentValue,
                                                    'border border-muted text-muted': !currentValue,
                                                    'border border-danger': hasError
                                                })}
                                                readOnly
                                                style={{ minWidth: 100 }}
                                                value={defaultValue}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                placeholder="Por favor ingrese el valor"
                                            />
                                            {
                                                !loadingUpdate ?
                                                    currentValue ?
                                                        <button
                                                            className="btn btn-xs btn-danger"
                                                            title="Remover bono"
                                                            onClick={() => handleChange({ target: { value: 0, name: 'value' } })}>
                                                            R
                                                        </button>
                                                        :
                                                        <button
                                                            className="btn btn-xs btn-primary"
                                                            title="Aplicar bono"
                                                            onClick={() => handleChange({ target: { value: defaultValue, name: 'value' } })}>
                                                            A
                                                        </button>
                                                    :
                                                    null
                                            }
                                            {
                                                loadingUpdate &&
                                                <button className="btn btn-xs btn-primary">
                                                    <div className="spinner" style={{ height: 15, width: 15 }}>
                                                        <div className="double-bounce1 bg-light"></div>
                                                        <div className="double-bounce2 bg-light"></div>
                                                    </div>
                                                </button>
                                            }

                                            {
                                                !loadingUpdate && hasError ?
                                                    <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                                                    :
                                                    null
                                            }
                                        </div>
                                    }
                                    {
                                        type === 'bloqueado' &&
                                        <div className="input-group">
                                            <CurrencyFormat
                                                className="form-control"
                                                readOnly
                                                style={{ minWidth: 100 }}
                                                value={defaultValue}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                            />
                                        </div>
                                    }
                                    {
                                        itemValue?.top &&
                                        <div className="w-100">
                                            <small>
                                                Valor maximo: <span className="text-primary">{Number(itemValue?.top).toLocaleString()}</span>
                                            </small>
                                        </div>
                                    }
                                </div>
                                :
                                currentValue == 0 ?
                                    'Sin valor.'
                                    :
                                    <p className="mb-0 text-primary" style={{ fontSize: '14px' }}>
                                        {Number(currentValue).toLocaleString()}
                                    </p>
                        }
                        <div className="text-center">
                            <button type="button" className="btn p-1 btn-xs" style={{ position: 'relative' }} title="Añadir nota" onClick={() => setShowDescription(true)}>
                                {
                                    currentNote &&
                                    <div style={{ background: 'green', position: 'absolute', top: 0, right: 0, borderRadius: '100%', width: 10, height: 10 }} />
                                }
                                <FaPencilAlt />
                            </button>
                        </div>
                        <Modal show={showDescription} onHide={() => setShowDescription(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Nota</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <textarea
                                    onChange={(e) => setCurrentNote(e.target.value)}
                                    name="note"
                                    cols="30"
                                    rows="10"
                                    className="form-control"
                                    style={{ minHeight: 250 }}
                                    value={currentNote}
                                    placeholder="Nota..."
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowDescription(false)}>
                                    Cerrar
                                </Button>
                                {
                                    canUpdateItems &&
                                    <Button variant="primary" onClick={handleChangeNote}>
                                        {
                                            loadingUpdate ?
                                                <div className="spinner" style={{ height: 15, width: 20, marginLeft: 10 }}>
                                                    <div className="double-bounce1 bg-primary"></div>
                                                    <div className="double-bounce2 bg-primary"></div>
                                                </div>
                                                :
                                                'Guardar cambios'
                                        }
                                    </Button>
                                }
                            </Modal.Footer>
                        </Modal>
                    </div>
                    :
                    <p className="mb-0 text-center">
                        N/A
                    </p>
            }
        </div>
    )
}

export default PayrrolWorkerItemEdit;