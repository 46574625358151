import { useEffect, useRef, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Modal, Table } from "react-bootstrap";
import { FaRegFileExcel, FaRegFilePdf, FaQuestionCircle } from "react-icons/fa";
import PayrrolWorkerItemEdit from "../../../components/Workers/PayrrolWorkerItemEdit";
import { useTheme } from "../../../context/ThemeContext";
import ChangePayrrolStatusComponent from "../../../components/Payrrols/ChangePayrrolStatusComponent";
import RenderStatus from "../../../components/RenderStatus";
import CreatePayrrolItemModal from "../../../components/CreatePayrrolItemModal";
import UserHavePermission from "../../../util/userHavePermissions";
import { mainPermissions } from "../../../util/MenuLinks";
import DeletePayrrolItemModal from "../../../components/DeletePayrrolItemModal";
import { FaSearch, FaUserGraduate } from "react-icons/fa";
import AddPayrrolWorkerModal from "../../../components/AddPayrrolWorkerModal";
import DeletePayrrolWorkerButton from "../../../components/DeletePayrrolWorkerButton";
import clsx from "clsx";


const PayrrolDetailPage = () => {

    const { id } = useParams();

    const { background } = useTheme();

    const [hiddenColumns, setHiddenColumns] = useState([]);

    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        itemSearch: '',
        workerSearch: '',
        positionId: '',
        page: 1,
        itemValues: {}
    })

    const [itemDescription, setItemDescription] = useState('');

    const [message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const [{ data, loading }, getPayrrol] = useAxios({ url: `/payrrols/${id}/render-detail`, params: filters }, { useCache: false });

    const [{ data: comments, loading: loadingComments }, getPayrrolComments] = useAxios({ url: `/payrrols/${id}/comments` }, { useCache: false });

    const [{ data: storeComment, loading: loadingStoreComments }, storePayrrolComment] = useAxios({ url: `/payrrols/${id}/comments`, method: 'POST' }, { useCache: false, manual: true });

    const [{ }, storeCodes] = useAxios({ url: `/mi-cuenta/codigos`, method: 'POST' }, { useCache: false, manual: true });

    const [{ data: dataCodes }, getCodes] = useAxios({ url: `/mi-cuenta/codigos` }, { useCache: false });

    const [{ data: positions, loading: loadingPositions }, getPositions] = useAxios({ params: { perPage: 100 } }, { manual: true, useCache: false });

    const [{ loading: loadingExport }, getExport] = useAxios(null, { manual: true, useCache: false });

    useEffect(() => {
        if (dataCodes) {
            setHiddenColumns(dataCodes);
        }
    }, [dataCodes])

    useEffect(() => {
        if (data?.costCenterId) getPositions({ url: `/cost-centers/${data?.costCenterId}/positions` })
    }, [data])

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleExport = (format) => {
        getExport({ url: `/payrrols/${id}/export/${format}`, responseType: 'blob' }).then((response, i) => {
            handleBlobResponse(response?.data);
        });
    }

    const handleBlobResponse = (blobResponse) => {
        const fileBlobUrl = URL.createObjectURL(blobResponse);
        const aToDownload = document.getElementById('downloadLink');
        /* aToDownload.download = fileName; */
        aToDownload.href = fileBlobUrl;
        aToDownload?.click();
        window.URL.revokeObjectURL(fileBlobUrl);
    }

    const handleItemValue = (itemCode, itemValue) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                itemValues: {
                    ...oldFilters?.itemValues,
                    [itemCode]: itemValue
                }
            }
        })
    }

    const handleComment = (e) => {
        e.preventDefault();

        storePayrrolComment({
            data: {
                message: message
            }
        }).then(() => {
            setMessage('');
            getPayrrolComments();
        })
    }

    const handleShowColumn = (code) => {
        let codes = hiddenColumns?.filter(columnCode => columnCode !== code) || [];
        setHiddenColumns(codes);
        storeCodes({ data: { codes } });
    }

    const handleHideColumn = (code) => {
        let codes = [...hiddenColumns, code];
        setHiddenColumns(codes);
        storeCodes({ data: { codes } });
    }

    return (
        <div>
            <div className="card p-4">
                <div className="d-flex align-items-center">
                    <div>
                        <h1>
                            {data?.costCenterName}
                        </h1>
                        <p className="text-primary mb-0">Mes y año: {data?.month}/{data?.year}</p>
                        <p className="text-primary mb-0">Trabajadores: {data?.workersCount}</p>
                    </div>
                    <div className="text-end ms-auto mb-3 d-flex justify-content-end align-items-center">
                        <button onClick={() => navigate(-1)} className="btn btn-primary me-2" style={{ marginLeft: 'auto', width: 'fit-content' }}>
                            Volver Atras
                        </button>
                        <Dropdown autoClose="outside">
                            <Dropdown.Toggle variant='primary'>
                                Exportar
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '50vh', overflowY: 'auto' }} className="custom-scrollbar scrollbar-primary">
                                {
                                    loadingExport ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <>
                                            <Dropdown.Item autoClose={false} onClick={() => handleExport('excel')} className="text-success">
                                                <FaRegFileExcel />
                                                Excel Resumen
                                            </Dropdown.Item>
                                            <Dropdown.Item autoClose={false} onClick={() => handleExport('excel-items')} className="text-success">
                                                <FaRegFileExcel />
                                                Excel Haberes
                                            </Dropdown.Item>
                                            <Dropdown.Item autoClose={false} onClick={() => handleExport('pdf')} className="text-danger">
                                                <FaRegFilePdf />
                                                PDF Resumen
                                            </Dropdown.Item>
                                        </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h5>
                        Progreso:
                    </h5>
                    <div className="col-md-4 d-flex align-items-center justify-content-end">
                        <ChangePayrrolStatusComponent
                            payrrol={data}
                            onChangeStatus={() => getPayrrol()}
                        />
                    </div>
                </div>
                <div className="card-body">
                    <RenderStatus status={data?.status} />
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="row w-100 align-items-center">
                        <div className="col-md-4">
                            <h4>
                                Haberes
                            </h4>
                            <a href={`/gestionar-nomina/detalle/pantalla-completa/${id}`}>
                                Ver en pantalla completa
                            </a>
                            <br />
                            {
                                UserHavePermission(mainPermissions?.payrrolWorkers[0]) &&
                                <button
                                    className="btn btn-primary btn-xs"
                                    onClick={() => setShowModal('add-worker')}
                                >
                                    Agregar trabajador
                                </button>
                            }
                        </div>
                        <div className="col-md-8" style={{ marginLeft: 'auto' }}>
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    {
                                        data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems) ?
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" size="xs" style={{ maxWidth: '100%' }}>
                                                    Gestionar Haberes
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[0]) ?
                                                            <Dropdown.Item onClick={() => setShowModal('create-items')} className="text-primary">
                                                                Agregar haber
                                                            </Dropdown.Item>
                                                            :
                                                            null
                                                    }
                                                    {/* {
                                                data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[1]) ?
                                                    <Dropdown.Item onClick={() => setShowModal('delete-items')} className="text-danger">
                                                        Eliminar haber
                                                    </Dropdown.Item>
                                                    :
                                                    null
                                            } */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            :
                                            null
                                    }
                                </div>
                                <div className="col-md-3">
                                    <p className="text-primary m-0">
                                        <FaUserGraduate /> Filtrar por cargo:
                                    </p>
                                    <select name="positionId" value={filters?.positionId} className="form-control border-primary" onChange={handleChange}>
                                        <option value="">
                                            Seleccione un Cargo...
                                        </option>
                                        {
                                            positions?.map((position, i) => {
                                                return (
                                                    <option value={position?.id} key={i}>
                                                        {position?.name} ({position?.workersCount})
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <p className="text-primary m-0">
                                        <FaSearch style={{ fontWeight: 'bold' }} /> Buscar trabajador:
                                    </p>
                                    <input
                                        type="text"
                                        className="form-control border-primary"
                                        onChange={handleChange}
                                        placeholder="Nombre, Cargo, Rut del trabajador..."
                                        name="workerSearch"
                                        value={filters?.workerSearch}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <p className="text-primary m-0">
                                        <FaSearch style={{ fontWeight: 'bold' }} /> Buscar haberes:
                                    </p>
                                    <input
                                        type="text"
                                        className="form-control border-primary"
                                        onChange={handleChange}
                                        placeholder="Código, Descripción del haber..."
                                        name="itemSearch"
                                        value={filters?.itemSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {
                        loading ?
                            <div className="spinner">
                                <div className="double-bounce1 bg-primary"></div>
                                <div className="double-bounce2 bg-primary"></div>
                            </div>
                            :
                            <div className="custom-scrollbar custom-horizontal-scrollbar scrollbar-primary" style={{ height: 400, overflowY: 'auto' }}>
                                <table>
                                    <thead>
                                        <tr style={{ zIndex: 10, left: 0, top: 0 }}>
                                            <th className="border-end border-bottom p-2" style={{
                                                width: 280,
                                                position: 'sticky',
                                                top: 0,
                                                left: 0,
                                                zIndex: 10,
                                                background: background?.value === 'dark' ? '#212130' : 'white',
                                            }}>
                                                <strong>Nombre del trabajador</strong>
                                                {
                                                    hiddenColumns?.length ?
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="primary" size="xs" style={{ maxWidth: '100%' }}>
                                                                Columnas ocultas ({hiddenColumns?.length})
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ maxHeight: 250, overflow: 'auto' }} className="custom-scrollbar scrollbar-primary">
                                                                {
                                                                    hiddenColumns?.map((code, i) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                onClick={() => handleShowColumn(code)}
                                                                                className="text-primary"
                                                                                key={`${i}-${code}-${i}`}
                                                                            >
                                                                                {code}
                                                                            </Dropdown.Item>
                                                                        )
                                                                    })
                                                                }
                                                                <Dropdown.Item
                                                                    onClick={() => setHiddenColumns([])}
                                                                    className="text-primary"
                                                                >
                                                                    Mostrar Todas
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        :
                                                        null
                                                }
                                            </th>
                                            {
                                                data?.items?.map((item, i) => {
                                                    return (
                                                        <th key={`${i}-${item?.code}-${i}-th`} style={{
                                                            zIndex: 9,
                                                            fontSize: 10,
                                                            width: 200,
                                                            position: 'sticky',
                                                            top: 0,
                                                            background: background?.value === 'dark' ? '#212130' : 'white',
                                                        }}
                                                            className={clsx(["text-dark text-center border-end border-bottom p-2 animate__animated animate__fadeInUp"], {
                                                                "d-none": hiddenColumns?.includes(item?.code)
                                                            })}
                                                        >
                                                            {
                                                                item?.cost_center_description &&
                                                                <div className="text-center">
                                                                    <FaQuestionCircle
                                                                        style={{ cursor: 'pointer', fontSize: 16 }}
                                                                        onClick={() => setItemDescription(item?.cost_center_description)}
                                                                    />
                                                                </div>
                                                            }
                                                            <small className="text-primary">
                                                                ({item?.code})
                                                            </small>
                                                            <br />
                                                            <strong>{item?.description}</strong>
                                                            {
                                                                item?.item_values && item?.item_values.length > 0 ?
                                                                    <div>
                                                                        <select
                                                                            className="rounded"
                                                                            onChange={(e) => handleItemValue(item?.code, e.target.value)}
                                                                            value={filters?.itemValues?.[item?.code]}
                                                                        >
                                                                            <option value="">Seleccione una opción</option>
                                                                            <option value="sin valor">Sin valor</option>
                                                                            {
                                                                                item?.item_values?.map((itemOption, i) => {
                                                                                    return (
                                                                                        <option value={itemOption} key={i}>
                                                                                            {itemOption?.toLocaleString()}
                                                                                        </option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <p>
                                                                        --
                                                                    </p>
                                                            }
                                                            <button className="btn btn-xs text-danger" onClick={() => handleHideColumn(item?.code)}>
                                                                Ocultar Columna
                                                            </button>
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.workers?.map((worker, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td style={{ width: 280, fontSize: 10, zIndex: 8, left: 0, position: 'sticky', background: background?.value === 'dark' ? '#212130' : 'white' }} className="text-dark border-end border-bottom p-2">
                                                        <div style={{ width: 200 }}>
                                                            <strong>{worker?.name}</strong>
                                                            <br />
                                                            <small className="text-primary">
                                                                {worker?.position_name}
                                                            </small>
                                                            <br />
                                                            <strong>{worker?.document_number || '--'}</strong>
                                                            {
                                                                UserHavePermission(mainPermissions?.payrrolWorkers?.[1]) &&
                                                                <DeletePayrrolWorkerButton
                                                                    payrrolWorker={worker}
                                                                    onDelete={() => getPayrrol?.()}
                                                                />
                                                            }
                                                        </div>
                                                    </td>
                                                    {
                                                        worker?.items?.map((itemValue, i) => {
                                                            return (
                                                                <td key={`${i}-${itemValue?.code}-td`} style={{
                                                                    fontSize: 10,
                                                                    width: 200,
                                                                }} className={clsx(["border-end border-bottom p-2 animate__animated animate__fadeInUp"], {
                                                                    "d-none": hiddenColumns?.includes(itemValue?.code)
                                                                })}>
                                                                    <PayrrolWorkerItemEdit
                                                                        positionId={worker?.position_id}
                                                                        code={itemValue?.code}
                                                                        costCenterId={data?.costCenterId}
                                                                        itemValue={itemValue}
                                                                        payrrolWorkerItemId={itemValue?.payrrolWorkerItemId}
                                                                        value={itemValue?.value}
                                                                        type={itemValue?.type}
                                                                        factor={itemValue?.factor}
                                                                        note={itemValue?.note}
                                                                        defaultValue={itemValue?.default_value}
                                                                        canUpdateItems={data?.canUpdate}
                                                                        setItemDescription={setItemDescription}
                                                                    />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">
                        Comentarios
                    </h4>
                </div>
                <div className="card-body">
                    {
                        comments?.data?.map((comment, i) => {
                            return (
                                <div key={i}>
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <b>{comment?.user?.name}</b>
                                        </div>
                                    </div>
                                    <p>
                                        {comment?.message} | <b>{comment?.updated_at}</b>
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="card-footer">
                    <form onSubmit={handleComment} className="d-flex align-items-center w-100">
                        <input type="text" className="form-control" placeholder="Escribe un comentario" value={message} onChange={(e) => setMessage(e.target.value)} />
                        <button disabled={loadingStoreComments} className="btn btn-xs btn-primary ms-1">
                            {
                                loadingStoreComments ?
                                    'Cargando'
                                    :
                                    'Enviar'
                            }
                        </button>
                    </form>
                </div>
            </div>
            {
                data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[0]) ?
                    <CreatePayrrolItemModal
                        show={showModal === 'create-items'}
                        setShowModal={setShowModal}
                        onCreate={() => getPayrrol()}
                        payrrolId={id}
                        costCenterId={data?.costCenterId}
                    />
                    :
                    null
            }
            {
                data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[1]) ?
                    <DeletePayrrolItemModal
                        show={showModal === 'delete-items'}
                        setShowModal={setShowModal}
                        onDelete={() => getPayrrol()}
                        payrrolId={id}
                        costCenterId={data?.costCenterId}
                    />
                    :
                    null
            }

            {
                data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkers[0]) ?
                    <AddPayrrolWorkerModal
                        show={showModal === 'add-worker'}
                        setShowModal={setShowModal}
                        onUpdate={() => getPayrrol()}
                        payrrolId={id}
                    />
                    :
                    null
            }

            <Modal show={itemDescription} onHide={() => setItemDescription(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Descripción</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {itemDescription}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PayrrolDetailPage;