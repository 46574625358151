import PositionColumns from "../components/CustomTable/Columns/PositionColumns";
import ModuleIndexPage from "../components/ModuleIndexPage";
import PositionFiltersComponent from "../components/Positions/PositionFiltersComponent";
import Dashboard from "../pages/private/Dashboard";
import CostCenterManagement from "../pages/private/cost-centers/CostCenterManagement";
import CostCenters from "../pages/private/cost-centers/CostCenters";
import CostCentersUpdate from "../pages/private/cost-centers/CostCentersUpdate";
import Items from "../pages/private/items/Items";
import PayrrolManagement from "../pages/private/payrrols/PayrrolManagement";
import PositionsUpdate from "../pages/private/positions/PositionsUpdate";

import { FaCoins, FaCommentDollar, FaUserGraduate, FaProjectDiagram, FaRegBuilding, FaUser } from "react-icons/fa";

import { GiStarFormation } from "react-icons/gi";





import SystemInfo from "./SystemInfo";

import { AiOutlineDashboard } from "react-icons/ai";
import PayrrolsFiltersComponent from "../components/Payrrols/PayrrolsFiltersComponent";
import PayrrolColumns from "../components/CustomTable/Columns/PayrrolColumns";
import PayrrolDetailPage from "../pages/private/payrrols/PayrrolDetailPage";
import CostCentersPositionsItemsUpdate from "../pages/private/cost-centers/CostCentersPositionsItemsUpdate";
import FactorColumns from "../components/CustomTable/Columns/FactorColumns";
import FactorsFiltersComponent from "../components/Factors/FactorsFiltersComponent";
import FactorsModuleActionsComponent from "../components/Factors/FactorsModuleActionsComponent";
import FactorsCreate from "../pages/private/factors/FactorsCreate";
import FactorsUpdate from "../pages/private/factors/FactorsUpdate";
import PayrrolsGlobalActions from "../components/Payrrols/PayrrolsGlobalActions";
import UserColumns from "../components/CustomTable/Columns/UserColumns";
import UsersFiltersComponent from "../components/Users/UsersFiltersComponent";
import UsersModuleActionsComponent from "../components/Users/UsersModuleActionsComponent";
import UsersUpdate from "../pages/private/users/UsersUpdate";
import UsersItemsImport from "../pages/private/users/UsersItemsImport";
import CostCentersItemsImport from "../pages/private/cost-centers/CostCentersItemsImport";
import FactorsImport from "../pages/private/factors/FactorsImport";
import CostCentersPositionsItemsUpdate2 from "../pages/private/cost-centers/CostCentersPositionsItemsUpdate2";
import PayrrolDetailPageFullScreen from "../pages/private/payrrols/PayrrolDetailPageFullScreen";
import IpcFiltersComponent from "../components/Ipc/IpcFiltersComponent";
import IpcColumns from "../components/CustomTable/Columns/IpcColumns";
import CostCentersEmailsImport from "../pages/private/cost-centers/CostCentersEmailsImport";
import CostCentersItemsDescriptionImport from "../pages/private/cost-centers/CostCentersItemsDescriptionImport";
import CostCenterColumns from "../components/CustomTable/Columns/CostCenterColumns";
import CostCentersFiltersComponent from "../components/CostCenters/CostCentersFiltersComponent";
import CostCentersModulesActionsComponent from "../components/CostCenters/CostCentersModulesActionsComponent";

const createLink = (
    title,
    hidden,
    Component,
    Icon,
    path,
    permissions,
    Columns,
    FiltersComponent,
    endPoint,
    GlobalActionsComponent,
    ModuleActionsComponent,
    children
) => ({ title, hidden, Component, Icon, path, permissions, Columns, FiltersComponent, endPoint, GlobalActionsComponent, ModuleActionsComponent, children });

const { systemCode } = SystemInfo;

export const mainPermissions = {
    users: [`${systemCode}-view-users`, `${systemCode}-update-users`],
    costCenters: [`${systemCode}-view-cost-centers`, `${systemCode}-update-cost-centers`],
    notificationEmails: [`${systemCode}-view-notification-emails`, `${systemCode}-create-notification-emails`, `${systemCode}-update-notification-emails`, `${systemCode}-delete-notification-emails`],
    items: [`${systemCode}-view-items`],
    positions: [`${systemCode}-view-positions`, `${systemCode}-update-positions`],
    instructions: [`${systemCode}-update-instructions`],
    payrrols: [`${systemCode}-view-payrrol`, `${systemCode}-create-payrrol`],
    payrrolWorkerItems: [`${systemCode}-create-payrrol-worker-items`, `${systemCode}-delete-payrrol-worker-items`],
    payrrolWorkers: [`${systemCode}-create-payrrol-workers`, `${systemCode}-delete-payrrol-workers`],
    factors: [`${systemCode}-view-factors`, `${systemCode}-create-factors`, `${systemCode}-update-factors`, `${systemCode}-delete-factors`],
    ipc: [`${systemCode}-view-ipc`],
}

const MenuLinks = [
    createLink('DashBoard', null, Dashboard, AiOutlineDashboard, '/dashboard', null),
    createLink('Gestionar Centro de costo', true, CostCenterManagement, AiOutlineDashboard, '/centro-de-costo/:id', null),
    createLink('Gestionar Nomina', true, PayrrolManagement, AiOutlineDashboard, '/gestionar-nomina/:id', null),
    createLink('Detalle de nomina', true, PayrrolDetailPage, AiOutlineDashboard, '/gestionar-nomina/detalle/:id', null),
    createLink('Detalle de nomina', true, PayrrolDetailPageFullScreen, AiOutlineDashboard, '/gestionar-nomina/detalle/pantalla-completa/:id', null),
    createLink('Centros de costo', false, ModuleIndexPage, FaRegBuilding, '/centros-de-costos', mainPermissions?.costCenters[0], CostCenterColumns, CostCentersFiltersComponent, 'cost-centers', null, CostCentersModulesActionsComponent),
    createLink('Centro de costo Importar Haberes', true, CostCentersItemsImport, null, '/centros-de-costos/importar-haberes', mainPermissions?.costCenters[1], null, null, 'cost-centers'),
    createLink('Centro de costo importar descripciones de los haberes', true, CostCentersItemsDescriptionImport, null, '/centros-de-costos/importar-descripcion-de-los-haberes', mainPermissions?.costCenters[1], null, null, 'cost-centers'),
    createLink('Centro de costo Importar Emails', true, CostCentersEmailsImport, null, '/centros-de-costos/importar-emails', mainPermissions?.costCenters[1], null, null, 'cost-centers'),
    createLink('Editar Centros de costo', true, CostCentersUpdate, AiOutlineDashboard, '/centros-de-costos/:id', mainPermissions?.costCenters[0]),
    createLink('Configurar haberes por cargos', true, CostCentersPositionsItemsUpdate, AiOutlineDashboard, '/centros-de-costos/:id/cargo/:positionId/configurar-haberes', mainPermissions?.costCenters[0]),
    createLink('Configurar haberes por cargos 2', true, CostCentersPositionsItemsUpdate2, AiOutlineDashboard, '/centros-de-costos/:id/cargo/:positionId/configurar-haberes-2', mainPermissions?.costCenters[0]),
    createLink('Haberes', false, Items, FaCoins, '/haberes', mainPermissions?.items[0]),
    createLink('Cargos', false, ModuleIndexPage, FaUserGraduate, '/cargos', mainPermissions?.positions[0], PositionColumns, PositionFiltersComponent, "positions"),
    createLink('Ipc', false, ModuleIndexPage, FaCommentDollar, '/ipc', mainPermissions?.ipc[0], IpcColumns, IpcFiltersComponent, "ipc"),
    createLink('Editar Cargo', true, PositionsUpdate, AiOutlineDashboard, '/cargos/:id', mainPermissions?.positions[0], null, null, "positions"),
    createLink('Nominas', false, ModuleIndexPage, FaProjectDiagram, '/nominas', mainPermissions?.payrrols[0], PayrrolColumns, PayrrolsFiltersComponent, "payrrols", PayrrolsGlobalActions),
    createLink('Factores', false, ModuleIndexPage, GiStarFormation, '/factores', mainPermissions?.factors[0], FactorColumns, FactorsFiltersComponent, "factors", null, FactorsModuleActionsComponent),
    createLink('Factores Importar', true, FactorsImport, null, '/factores/importar', mainPermissions?.factors[1], null, null, "factors"),
    createLink('Factores Crear', true, FactorsCreate, null, '/factores/crear', mainPermissions?.factors[1], null, null, 'factors'),
    createLink('Factores Editar', true, FactorsUpdate, null, '/factores/:id', mainPermissions?.factors[2], null, null, 'factors'),
    createLink('Trabajadores Importar Haberes', true, UsersItemsImport, null, '/trabajadores/importar-haberes', mainPermissions?.users[1], null, null, 'users'),
    createLink('Trabajadores Editar', true, UsersUpdate, null, '/trabajadores/:id', mainPermissions?.users[1], null, null, 'users'),
    createLink('Trabajadores', false, ModuleIndexPage, FaUser, '/trabajadores', mainPermissions?.users[0], UserColumns, UsersFiltersComponent, "users", null, UsersModuleActionsComponent),
];

export default MenuLinks;