import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import profileImg from '../../../images/profile.png';
import { useFeedBack } from "../../../context/FeedBackContext";

const PositionsUpdate = ({ title, endPoint }) => {

    const { setCustomAlert } = useFeedBack();

    const { id } = useParams();

    const [filters, setFilters] = useState({ search: '', page: 1 });

    const [itemsFilters, setItemsFilters] = useState({ search: '', page: 1, perPage: 1000 });

    const [currentWorkers, setCurrentWorkers] = useState([]);

    const [currentPositionItemCodes, setCurrentPositionItemCodes] = useState([]);

    const [{ data, loading }, getRecord] = useAxios({ url: `/${endPoint}/${id}` }, { useCache: false });

    const [{ data: workersResponse, loading: loadingWorkers }] = useAxios({ url: `/${endPoint}/${id}/users`, params: filters }, { useCache: false });

    const [{ data: itemsResponse, loading: loadingItems }] = useAxios({ url: `/items`, params: itemsFilters }, { useCache: false });

    const [{ data: updateResponse, loading: loadingUpdate }, updateRecord] = useAxios({ url: `/${endPoint}/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (data) {
            setCurrentPositionItemCodes(data?.data?.items?.map((item => item?.code)));
        }
    }, [data])

    useEffect(() => {
        setCustomAlert({
            show: updateResponse,
            message: "El cargo ha sido actualizado exitosamente",
            severity: "success",
            title: '¡Enhorabuena!'
        })
    }, [updateResponse])

    useEffect(() => {
        if (workersResponse) {
            setCurrentWorkers((oldWorkers) => [...oldWorkers, ...workersResponse?.data]);
        }
    }, [workersResponse]);

    const handleChange = (e) => {
        setCurrentWorkers([]);

        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleItem = (itemCode) => {

        if (currentPositionItemCodes?.includes(itemCode)) {
            setCurrentPositionItemCodes((oldValues) => {
                const newValues = oldValues?.filter((value) => value !== itemCode);
                return newValues;
            })
        }

        if (!currentPositionItemCodes?.includes(itemCode)) setCurrentPositionItemCodes((oldValues) => [...oldValues, itemCode]);

    }

    const handleAddItems = () => {
        updateRecord({
            data: {
                itemCodes: currentPositionItemCodes
            }
        });
    }

    return (
        <div>
            <div className="card p-4">
                <h3>
                    {data?.data?.name}
                </h3>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header px-2">
                            <div className="row w-100 align-items-center">
                                <div className="col-md-6 mb-3">
                                    <h4 className="mb-0 text-primary">
                                        <FaRegUser /> Trabajadores con este cargo
                                    </h4>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input type="text" className="form-control" placeholder="Buscar..." name="search" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 py-0">
                            <ul className="custom-scrollbar scrollbar-primary" style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                {
                                    currentWorkers?.map((worker, i) => {
                                        return (

                                            <li key={i} className="mb-2 px-3">
                                                <div className="d-flex align-items-center">
                                                    <img src={worker?.imagePath || profileImg} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                                                    <div>
                                                        <p className="text-primary mb-0">{worker?.name}</p>
                                                        <p className="text-dark mb-0"><b>{worker?.position?.name}</b></p>
                                                        <p className="mb-0">{worker?.documentNumber}</p>
                                                    </div>
                                                </div>

                                            </li>
                                        )
                                    })
                                }
                                {
                                    !loadingWorkers && workersResponse?.meta?.last_page > filters?.page ?
                                        <li className="text-center my-3">
                                            <button className="btn btn-xs btn-primary" onClick={() => setFilters((oldFilters) => ({ ...oldFilters, page: oldFilters?.page + 1 }))}>
                                                Cargar mas
                                            </button>
                                        </li>
                                        :
                                        null
                                }
                                {
                                    loadingWorkers &&
                                    <li className="text-center my-5">
                                        Cargando...
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PositionsUpdate;