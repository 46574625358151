import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";
import 'animate.css';
import 'swiper/css/bundle';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeContextProvider } from './context/ThemeContext';
import { AuthProvider } from './context/AuthContext';
import { FeedBackProvider } from './context/FeedBackContext';
import { EchoProvider } from './context/EchoContext';

ReactDOM.render(
  <BrowserRouter>
    <ThemeContextProvider>
      <AuthProvider>
        <EchoProvider>
          <FeedBackProvider>
            <App />
          </FeedBackProvider>
        </EchoProvider>
      </AuthProvider>
    </ThemeContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
